import { createRoot } from 'react-dom/client'
import React, { StrictMode, lazy, Suspense } from 'react'
import { Provider as ThemeProvider } from '@chilipiper/design-system'
import { rolloutInit } from '@chilipiper/rollout'
import { kcContext } from './app/kcContext'
import '@chilipiper/design-system/css/reset.css'

const SignUpPage = lazy(() => import('./pages/signup'))
const KcApp = lazy(() => import('./app'))

if (kcContext !== undefined) {
  // eslint-disable-next-line no-console
  console.debug('Keycloak Context', kcContext)
}

rolloutInit('keycloak')

const root = createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <StrictMode>
    <Suspense>
      {kcContext === undefined ? (
        <ThemeProvider>
          <SignUpPage />
        </ThemeProvider>
      ) : (
        <KcApp kcContext={kcContext} />
      )}
    </Suspense>
  </StrictMode>
)
